import * as queryString from 'query-string';
import {Link} from 'react-router-dom';

/** Link that loads a GEDCOM file from URL. */
function GedcomLink(props: {url: string; text: string}) {
  return (
    <Link
      to={{pathname: '/view', search: queryString.stringify({url: props.url})}}
    >
      {props.text}
    </Link>
  );
}

function formatBuildDate(dateString: string) {
  return dateString?.slice(0, 16) || '';
}

/** The intro page. */
export function Intro() {
  return <div id="content"></div>;
}

