import * as queryString from 'query-string';
import {Dropdown, Icon, Menu} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {IndiInfo, JsonGedcomData} from 'topola';
import {Link} from 'react-router-dom';
import {Media} from '../util/media';
import {MenuType} from './menu_item';
import {SearchBar} from './search';
import {UploadMenu} from './upload_menu';
import {UrlMenu} from './url_menu';
import {useHistory, useLocation} from 'react-router';
import {WikiTreeLoginMenu, WikiTreeMenu} from './wikitree_menu';

enum ScreenSize {
  LARGE,
  SMALL,
}

interface EventHandlers {
  onSearchSelection: (indiInfo: IndiInfo) => void;
  onPrint: () => void;
  onDownloadPdf: () => void;
  onDownloadPng: () => void;
  onDownloadSvg: () => void;
}

interface Props {
  /** True if the application is currently showing a chart. */
  showingChart: boolean;
  /** Data used for the search index. */
  data?: JsonGedcomData;
  standalone: boolean;
  /** Whether to show the "All relatives" chart type in the menu. */
  allowAllRelativesChart: boolean;
  eventHandlers: EventHandlers;
  /** Whether to show additional WikiTree menus. */
  showWikiTreeMenus: boolean;
}

export function TopBar(props: Props) {
  const history = useHistory();
  const location = useLocation();

  function changeView(view: string) {
    const search = queryString.parse(location.search);
    if (search.view !== view) {
      search.view = view;
      location.search = queryString.stringify(search);
      history.push(location);
      if (view === 'relatives') {
        window.parent.postMessage(
          {
            type: 'click',
            message: 'relatives',
          },
          '*',
        );
      }
    }
  }

  function defaultMenu() {
    if (!props.showingChart) {
      return null;
    }
    return (
      <>
        <span className="ui relatives" onClick={() => changeView('relatives')}>
          <Icon name="users" />
          <FormattedMessage
            id="menu.relatives"
            defaultMessage="View All relatives"
          />
        </span>
        <SearchBar
          data={props.data!}
          onSearchSelection={props.eventHandlers.onSearchSelection}
          {...props}
        />
      </>
    );
  }

  return (
    <>
      <Menu attached="top" inverted color="blue">
        {defaultMenu()}
      </Menu>
    </>
  );
}

